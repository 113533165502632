import searchIcon from "../../../../assets/svg/dashboard/search.svg";

const TransactionFilter = ({
  toggleModal,
  accountNumberFilter,
  setAccountNumberFilter,
  phoneNumberFilter,
  setPhoneNumberFilter,
  vendorFilter,
  setVendorFilter,
  channelFilter,
  setChannelFilter,
  statusFilter,
  setStatusFilter,
  vendors,
  networkFilter,
  setNetworkFilter,
}) => {
  const channels = [
    {
      value: 1,
      name: "USSD",
    },
    {
      value: 2,
      name: "ATM",
    },
    {
      value: 3,
      name: "MOBILE",
    },
    {
      value: 4,
      name: "INTERNET",
    },
  ];

  const network = [
    {
      value: 1,
      name: "MTN",
    },
    {
      value: 2,
      name: "GLO",
    },
    {
      value: 3,
      name: "AIRTEL",
    },
    {
      value: 4,
      name: "9MOBILE",
    },
  ];

  const status = [
    {
      value: "PurchasePending",
      name: "Pending",
    },
    {
      value: "PurchaseSuccessful",
      name: "Successful",
    },
    {
      value: "PurchaseFailed",
      name: "Failed",
    },
    {
      value: "DebitReversed",
      name: "Reversed",
    },
  ];

  const handleClearAll = () => {
    setAccountNumberFilter("");
    setPhoneNumberFilter("");
    setVendorFilter("");
    setChannelFilter("");
    setStatusFilter("");
    setNetworkFilter("");
  };

  return (
    <div
      style={{
        maxHeight: "85vh",
        padding: "60px 35px 15px 35px",
        overflowY: "scroll",
      }}
    >
      <h2 className="transaction-filter-header">Filters</h2>
      <div
        style={{
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <div className="transaction-filter-div">
          <label htmlFor="accNo" className="transaction-filter-label">
            Account No
          </label>

          <div className="transaction-filter-cont">
            <input
              type="search"
              name="accNo"
              placeholder="Acc No"
              value={accountNumberFilter}
              onChange={(e) => {
                setAccountNumberFilter(e.target.value);
              }}
              className="transaction-filter-input"
            />
            <div className="transaction-header-filter-icon">
              <img src={searchIcon} alt="search icon" />
            </div>
          </div>
        </div>

        <div className="transaction-filter-div">
          <label htmlFor="accNo" className="transaction-filter-label">
            Phone No
          </label>

          <div className="transaction-filter-cont">
            <input
              type="search"
              placeholder="Phone No"
              value={phoneNumberFilter}
              onChange={(e) => {
                setPhoneNumberFilter(e.target.value);
              }}
              className="transaction-filter-input"
            />
            <div className="transaction-header-filter-icon">
              <img src={searchIcon} alt="search icon" />
            </div>
          </div>
        </div>

        <div className="transaction-filter-div">
          <label htmlFor="accNo" className="transaction-filter-label">
            Vendor
          </label>
          <select
            name="statusFilter"
            id=""
            className="transaction-filter-select"
            onChange={(e) => {
              setVendorFilter(e.target.value);
            }}
            value={vendorFilter}
          >
            <option value="">All</option>
            {vendors?.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="transaction-filter-div">
          <label htmlFor="accNo" className="transaction-filter-label">
            Channel
          </label>
          <select
            name="channelFilter"
            id=""
            className="transaction-filter-select"
            onChange={(e) => {
              setChannelFilter(e.target.value);
            }}
            value={channelFilter}
          >
            <option value="">All</option>
            {channels?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="transaction-filter-div">
          <label htmlFor="accNo" className="transaction-filter-label">
            Network
          </label>
          <select
            name="channelFilter"
            id=""
            className="transaction-filter-select"
            onChange={(e) => {
              setNetworkFilter(e.target.value);
            }}
            value={networkFilter}
          >
            <option value="">All</option>
            {network?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="transaction-filter-div">
          <label htmlFor="accNo" className="transaction-filter-label">
            Status
          </label>
          <select
            name="statusFilter"
            id=""
            className="transaction-filter-select"
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            value={statusFilter}
          >
            <option value="">All</option>

            {status?.map((item, idx) => (
              <option key={idx} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
      </div>{" "}
      <div
        style={{
          width: "100%",
          marginTop: "50px",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <button
          onClick={() => {
            toggleModal(false);
          }}
          className="transaction-filter-btn "
          style={{
            backgroundColor: "#255CFF",
            color: "#fff",
          }}
        >
          Apply Filter
        </button>
        <button
          onClick={handleClearAll}
          className="transaction-filter-btn "
          style={{
            backgroundColor: "#EDF2F7",
            color: "#000",
          }}
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default TransactionFilter;
