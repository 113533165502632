import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const RightModal = ({ children, isOpen, toggleModal, width, borderRadius }) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "0%",
            right: "0%",
            outline: "0",
            width: width,
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "100%",
            borderRadius: borderRadius,
          }}
          className="overflow-y-scroll scrollbar"
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
};
export default RightModal;
